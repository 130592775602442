.ah-columns {
  background-color: cadetblue;

  .ah-error-message {
    color: red;
  }

  .add-column-wrapper {
    margin-bottom: 20px;
  }
}
